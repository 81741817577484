.billable-events-masonry-grid {
    display: flex;
    margin-left: -20px; /* Adjust the gutter offset */
    width: auto;
}

.billable-events-masonry-column {
    padding-left: 20px; /* This defines the gutter size */
    background-clip: padding-box;
}
