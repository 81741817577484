/* Organization Row Styling */
.organization-table-row {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    align-items: center;
    position: relative; /* Allows action tray to be positioned properly */
    overflow: visible; /* Ensures dropdowns are not clipped */
    white-space: nowrap;
}

.organization-table-cell {
    flex: 1;
    min-width: 120px; /* Ensures readability on smaller screens */
    padding: 8px 10px;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #f3f3f3;
}

.organization-table-row:hover {
    background-color: #f1f1f1;
}

/* Action Tray Cell */
.organization-action-tray-table-cell {
    position: relative; /* This ensures it can contain an absolute positioned tray */
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    overflow: visible;
}

/* Action Tray Dropdown */
.organization-action-tray-table-cell .action-tray {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    z-index: 10; /* Ensures it appears above other content */
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px;
    display: none; /* Hidden by default */
}

/* Show tray on hover */
.organization-action-tray-table-cell:hover .action-tray {
    display: block;
}

/* Scrollable Container for Overflowing Content */
.organization-table-scroll-container {
    max-height: 700px;
    overflow-x: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Tablet and Up */
@media (min-width: 768px) {
    .organization-table-row {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 0.5fr 0.5fr 1fr;
        padding: 10px 0;
        white-space: normal;
    }

    .organization-table-cell {
        flex: unset;
        padding: 12px 15px;
        text-align: left;
        font-size: 14px;
        border: none;
        min-width: unset;
    }
}

/* Desktop Enhancements */
@media (min-width: 1024px) {
    .organization-table-row {
        padding: 12px 0;
    }

    .organization-table-scroll-container {
        max-height: none;
        overflow-y: visible;
    }
}

/* Highlight Rows Missing DOT Account */
.missing-dot-account {
    background-color: #ffefef;
}
