.billable-events-page-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f9fc;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.billable-events-section {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
}

.list-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 10px;
}
.bulk-rebill-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust the height as needed (60px ~ 1 inch) */
    background-color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.bulk-rebill-input {
    flex: 1;
    max-width: 400px;
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.bulk-rebill-btn {
    padding: 10px 20px;
    background-color: #fff;
    color: #007bff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.bulk-rebill-btn:hover {
    background-color: #f0f0f0;
}
