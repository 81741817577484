.billable-event-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.billable-event-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Selected state */
.billable-event-item.selected {
    background-color: #e7f1ff;
    border-color: #007bff;
}

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

/* Target direct child divs for spacing */
.header-left > div,
.header-right > div {
    margin-right: 10px;
}

/* Emphasize specimen id */
.event-master-result {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}

/* Billing tag styling (billing id) */
.billable-tag {
    background-color: #e0f7fa; /* light cyan tint */
    color: #007bff;
    font-weight: bold;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 10px;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;

}

/* Event date styling */
.event-date {
    font-size: 14px;
    color: #666;
}

/* Styling for status badges */
.event-status {
    display: inline-block;
}

.event-status-text {
    display: inline-block;
    word-break: break-word;
    background-color: #d4edda;
    color: #155724;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 13px;
}

/* Details section for clarity and legibility */
.event-details {
    font-size: 13px;
    color: #666;
    line-height: 1.6;
}

.event-details > div {
    margin-bottom: 5px;
}
