.lab-screenData-row {
    display: grid;
    grid-template-columns: 115px 225px 85px 85px 85px 115px 85px 85px 85px;
    gap: 10px;
    padding: 10px;
    align-items: center;
    overflow: visible;
    z-index: 5;
}

.mro-page-cell {
    text-align: center;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mro-page-cell-action-tray {
    text-align: center;
    padding: 5px 10px;
    overflow: visible;
    white-space: nowrap;
}

.mro-custom-select-container {
    position: relative;
    width: 100%;
    min-width: 150px; /* Ensure the select dropdown has enough width */
    max-width: 100%; /* Make sure it doesn't overflow the container */
}

.mro-custom-select-container .select__control {
    min-width: 180px; /* Ensure control is wide enough */
}

.mro-custom-select-container .select__menu {
    width: auto; /* Let menu adjust automatically */
    z-index: 1002;
}

.mro-custom-select-container .select__menu-list {
    max-height: 200px; /* Limit max height for better visibility */
    overflow-y: auto; /* Allow scrolling */
}

.dropdown-cell {
    position: relative;
    width: 100%;
}


.ccf-icon {
    width: 24px;
    height: 24px;
}

.dull-icon {
    opacity: 0.1;
}

.ccf-placeholder {
    color: #ccc; /* Light grey placeholder */
    text-align: center;
}

.highlighted-row {
    background-color: rgba(0, 123, 255, 0.2); /* Light blue highlight */
    transition: background-color 0.3s ease-in-out;
}