/* Full-screen modal background */
.specimen-search-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f9fc;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

/* Centered modal container */
.specimen-search-container {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

/* Labb logo */
.specimen-search-logo {
    width: 120px;
    margin-bottom: 30px;
}

/* Input field styling */
.specimen-input {
    width: 100%;
    padding: 12px 15px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.specimen-input:focus {
    border-color: #007BFF;
}

/* Search button */
.search-btn {
    width: 100%;
    padding: 12px 15px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.search-btn:hover {
    background-color: #0056b3;
}

/* Disabled button */
.search-btn.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Status messages */
.status-message {
    margin-top: 15px;
    font-size: 14px;
}

.status-message.success {
    color: green;
}

.status-message.error {
    color: red;
}
