/* Billing Page Styling */
.billing-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

/* Action & Section Styling */
.billing-actions,
.billing-section {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

/* Specimen List Styling */
.specimen-list-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    overflow: hidden;
}

/* Header Styling */
.specimen-list-header {
    display: flex;
    font-weight: bold;
    background: #e3e6eb;
    padding: 12px;
    border-bottom: 2px solid #ccc;
}

/* Individual Header Items */
.specimen-header-item {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #333;
}

/* Specimen Row Styling */
.specimen-list-row {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease-in-out;
}

/* Hover Effect for Better UX */
.specimen-list-row:hover {
    background-color: #f9f9f9;
}

/* Individual Specimen Items */
.specimen-item {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #555;
    padding: 8px;
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 768px) {
    .billing-actions,
    .billing-section {
        padding: 10px;
    }

    .specimen-list-header,
    .specimen-list-row {
        flex-wrap: wrap;
        font-size: 12px;
    }

    .specimen-header-item,
    .specimen-item {
        flex-basis: 50%; /* Stack on smaller screens */
        padding: 6px;
    }
}
